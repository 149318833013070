import React, {useState} from 'react';
import ReactPlayer from 'react-player'

import './App.css';
import testAudio from './test.wav';

import script from './script.json';



function App() {

  const [text, setText] = useState([]);
  const [usedWordIds, setUsedWordIds] = useState([]);

  const onProgress = (e) => {
    let i = 0;
    for (let el of script) {
      const startTime = parseFloat(el[1]);
      if (e.playedSeconds > startTime) {
        if(!usedWordIds.includes(i)){
          setText(text => [...text, {word: el[0], confidence: el[2]} ])
          // eslint-disable-next-line no-loop-func
          setUsedWordIds(usedWordIds => [...usedWordIds, i])
          break;
        } 
        i ++;        
      }
    }
  }

  // const reset = () => {
  //   setUsedWordIds([]);
  //   setText([]);
  // }

  return (
    <div className="App">
      <div className="left">
        <ol>
          <li>Audio is processed with Google Cloud Speech to Text API</li>
          <li>A JSON array containing the returned `word`, `startTime` and `confidence` values is saved to a file.</li>
          <li>As the audio plays, its current timecode is checked against the startTime values in the file</li>
          <li>Words with a startTime greater than the current timecode are displayed</li>
          <li>The opacity of a word designates how confident the Speech to Text API is that the word is a correct match</li>
        </ol>
        <ReactPlayer
          url={testAudio}
          onProgress={onProgress}
          progressInterval={100}
          autoPlay
          controls
          width="100%"
          height={100}
          style={{marginBottom:50}}
        />
        <div>
          {text.map(word => {
            return <span style={{opacity: word.confidence * 0.8}}>{`${word.word} `}</span>;
          })}
        </div>
      </div>
      <div className="right">
        <pre style={{textAlign:"left"}}>
          {JSON.stringify(script, null, 2)}
        </pre>
      </div>
    </div>
  );
}

export default App;
